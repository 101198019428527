import './App.css';

function App() {
  const jobId = new URLSearchParams(window.location.search).get("jobId");
  const type = new URLSearchParams(window.location.search).get("type");
  const data = {
    configurations: {
      jobId: jobId ? jobId : "ABC123",
      applicationType: type ? type : "DREES_BUILD",
    },
  };
  window.cov = data;
  return (
    <div>
      <button onClick={() => window.loadPlugin(data.configurations)}>
        Load modal
      </button>
      <div id="cov-plugin-app"></div>
    </div>
  );
}

export default App;
